import React from "react";
import PropTypes from "prop-types";

export function H0({children, className}) {
    return (
        <h1 className={`font-bold text-3xl md:text-5xl lg:text-6xl ${className}`}>{children}</h1>
    )
}
H0.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export function H1({children, className}) {
    return (
        <h1 className={`font-bold text-2xl md:text-3xl lg:text-4xl ${className}`}>{children}</h1>
    )
}
H1.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export function H2({children, className}) {
    return (
        <h2 className={`font-semibold text-2xl ${className}`}>{children}</h2>
    )
}
H2.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export function H3({children, className}) {
    return (
        <h3 className={`font-semibold text-lg ${className}`}>{children}</h3>
    )
}
H3.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export function P({children, className}) {
    return (
        <p className={`text-sm md:text-base font-light mb-2 ${className}`}>{children}</p>
    )
}
P.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}
